import React , {useLayoutEffect} from "react"
import { Link, graphql, navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"


function Index (props) {
  const {location } = props
  const rootPath = `${__PATH_PREFIX__}/`

  useLayoutEffect(() => {
      if (location.pathname === rootPath) {
        navigate('/es/', {replace: true});
      }
    })

    return null
  }


export default Index
